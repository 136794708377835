import React from 'react';
import _ from 'lodash';
import { TableBuilderPropsOnly, TableBuilderPropsOnlyProps } from '@/tableBuilder/TableBuilderPropsOnly.organism';
import { ColumnRowGroupChangedEvent } from '@ag-grid-community/core';
import { ColumnOrRow, ColumnOrRowWithDefinitions } from '@/tableBuilder/tableBuilder.types';
import { tableBuilder } from '@/tableBuilder/tableBuilder.utilities';
import { tableBuilderContent } from '@/tableBuilder/tableBuilderContent.utilities';
import { useStateEffect } from '@/core/hooks/useStateEffect.hook';

export const TableBuilderContent: React.FunctionComponent<TableBuilderPropsOnlyProps> = (props) => {
  const [simpleTableData, setSimpleTableData] = useStateEffect(props.simpleTableData);
  const [conditionTableData, setConditionTableData] = useStateEffect(props.conditionTableData);

  const updateData = (columns: ColumnOrRowWithDefinitions[]) => {
    if (props.isSimpleMode) {
      setSimpleTableData(tableBuilder.processSimpleTableData(props.rawSimpleTableData, props.isTransposed, columns));
    } else {
      setConditionTableData({
        headers: props.conditionTableData.headers,
        capsules: tableBuilderContent.processConditionTableData({
          data: props.rawConditionTableData.capsules,
          columnsWithIndex: tableBuilderContent.getColumnsWithIndex(
            props.isSimpleMode ? columns : props.conditionColumns.rows,
          ),
          conditionTableRows: columns,
          tableItems: props.tableItems,
          isTransposed: props.isTransposed,
        }),
      });
    }
  };

  return (
    <TableBuilderPropsOnly
      {...props}
      simpleTableData={simpleTableData}
      conditionTableData={conditionTableData}
      moveColumn={(key, newKey) => {
        if (props.isSimpleMode) {
          const index = props.simpleColumns.findIndex((column: ColumnOrRow) => column.key === key);
          const newIndex = props.simpleColumns.findIndex((column: ColumnOrRow) => column.key === newKey);
          if (index !== -1 && newIndex !== -1) {
            const column = props.simpleColumns[index];
            props.simpleColumns.splice(index, 1);
            props.simpleColumns.splice(newIndex, 0, column);

            _.forEach(props.simpleTableData, (row) => {
              const cellValue = row.cells[index];
              row.cells.splice(index, 1);
              row.cells.splice(newIndex, 0, cellValue);
            });
          }
        }
      }}
      // Causes an error when we try to call the function, but we don't use this except for on table load, so just noop,
      handleRowGroupOpened={_.noop}
      handleRowGroupChanged={(event: ColumnRowGroupChangedEvent<any, any>) => {
        if (props.isSimpleMode) {
          if (event.columns) {
            event.columns
              .filter((column) => column.isRowGroupActive())
              .forEach((agGridColumn) => {
                const column = props.simpleColumns.find(
                  (column: ColumnOrRow) => column.key === agGridColumn.getColId(),
                )!;
                column.grouping = true;
              });

            event.columns
              .filter((column) => !column.isRowGroupActive())
              .forEach((agGridColumn) => {
                const column = props.simpleColumns.find(
                  (column: ColumnOrRow) => column.key === agGridColumn.getColId(),
                )!;
                column.grouping = false;
              });

            const rowGroupOrdering = event.columnApi.getRowGroupColumns().map((c) => c.getColId());
            props.simpleColumns.forEach((column: ColumnOrRow) => {
              const index = rowGroupOrdering.indexOf(column.key);
              if (index !== -1) {
                column.rowGroupOrder = index;
              } else {
                delete column.grouping;
              }
            });
          }
        }
      }}
      maxSortLevel={tableBuilder.getMaxSortLevel(
        props.isSimpleMode ? props.simpleColumns : props.conditionColumns.rows,
      )}
      setColumnFilter={(key, filter) => {
        const columns = props.isSimpleMode
          ? tableBuilderContent.addColumnFilter(props.simpleColumns, key, filter)
          : tableBuilderContent.addColumnFilter(props.conditionColumns.rows, key, filter);
        updateData(columns);
      }}
      sortByColumn={(key, direction) => {
        const columns = props.isSimpleMode
          ? tableBuilderContent.sortByColumn(props.simpleColumns, key, direction)
          : tableBuilderContent.sortByColumn(props.conditionColumns.rows, key, direction);
        updateData(columns);
      }}
      hideInteractiveContentActions={
        !!props.updateContentMeasurements &&
        (props.isSimpleMode ? _.isUndefined(props.rawSimpleTableData) : _.isUndefined(props.rawConditionTableData))
      }
    />
  );
};
